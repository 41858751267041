import classNames from "classnames"
import { observer } from "mobx-react"
import { Suspense, createRef, useEffect } from "preact/compat"
import { Check, ChevronDown, Info, Save, Settings, Upload } from "react-feather"
import {
	Button,
	Checkbox,
	CheckboxGroup,
	FormControl,
	HiddenVisually,
	Link,
	Popover,
	Switch,
	Text,
	View,
	useTheme,
	useToast,
} from "reshaped"

import { AceEditor } from "#components/AceEditor"

import { pipelineDetailPageStore } from "../page.model"
import css from "./TransformerFunction.module.css"

export const TransformerFunction = observer(() => {
	const { colorMode } = useTheme()
	const toast = useToast()

	const fileInput = createRef<HTMLInputElement>()
	const editor = createRef()

	useEffect(() => {
		if (editor.current) {
			editor.current.editor.setOption(
				"wrap",
				pipelineDetailPageStore.transformer.editorSettings.softWrap,
			)

			editor.current.editor.setOption(
				"showInvisibles",
				pipelineDetailPageStore.transformer.editorSettings
					.showInvisibles,
			)
		}
	}, [editor])

	return (
		<View direction="column" gap={3}>
			<View direction="row" gap={3}>
				<Popover position="bottom-start">
					<Popover.Trigger>
						{(attributes) => (
							<Button
								attributes={attributes}
								icon={Settings}
								variant="faded"
								color="primary"
							>
								Editor Settings
							</Button>
						)}
					</Popover.Trigger>
					<Popover.Content>
						<View direction="column" gap={4}>
							<Switch
								size="small"
								name="softWrap"
								checked={
									pipelineDetailPageStore.transformer
										.editorSettings.softWrap
								}
								onChange={({ checked }) => {
									pipelineDetailPageStore.transformer.editorSettings.setSoftWrap(
										checked,
									)
								}}
							>
								Soft Wrap
							</Switch>
							<Switch
								size="small"
								name="showInvisibles"
								checked={
									pipelineDetailPageStore.transformer
										.editorSettings.showInvisibles
								}
								onChange={({ checked }) => {
									pipelineDetailPageStore.transformer.editorSettings.setShowInvisibles(
										checked,
									)
								}}
							>
								Show Invisibles
							</Switch>
						</View>
					</Popover.Content>
				</Popover>

				<View grow align="end">
					<View direction="row" gap={3}>
						<Popover>
							<Popover.Trigger>
								{(attributes) => (
									<Button
										attributes={attributes}
										endIcon={ChevronDown}
										variant="faded"
									>
										Dependencies
									</Button>
								)}
							</Popover.Trigger>
							<Popover.Content>
								<View gap={5} width="230px">
									<Text variant="caption-1" wrap="balance">
										Any dependencies you select here will be
										made available to your Transformer.
										<View paddingTop={1}>
											<Link
												icon={Info}
												href="https://docs.glassflow.dev/concepts/data-transformation#python-dependencies-for-transformation"
												variant="plain"
											>
												Learn more
											</Link>
										</View>
									</Text>

									<CheckboxGroup
										name="requirements"
										value={
											pipelineDetailPageStore.transformer
												.form.requirements
										}
										onChange={({ value }) => {
											pipelineDetailPageStore.transformer.form.setRequirements(
												value,
											)
										}}
									>
										<View gap={4}>
											<Checkbox value="langchain">
												langchain
											</Checkbox>
											<Checkbox value="langchain-openai">
												langchain-openai
											</Checkbox>
											<Checkbox value="openai">
												openai
											</Checkbox>
											<Checkbox value="langchain-community">
												langchain-community
											</Checkbox>
										</View>
									</CheckboxGroup>
								</View>
							</Popover.Content>
						</Popover>
						<HiddenVisually>
							<input
								type="file"
								ref={fileInput}
								accept=".py"
								onChange={() => {
									const files = fileInput.current?.files

									if (files?.length) {
										const file = files[0]

										let reader = new FileReader()

										reader.addEventListener(
											"load",
											(event) => {
												if (
													typeof event.target
														?.result === "string"
												) {
													pipelineDetailPageStore.transformer.form.setTransformer(
														event.target.result,
													)
												}
											},
										)

										reader.readAsText(file, "UTF-8")
									}
								}}
							/>
						</HiddenVisually>
						<Button
							variant="faded"
							color="primary"
							onClick={() => {
								fileInput.current?.click()
							}}
							icon={Upload}
						>
							Upload File
						</Button>
						<Button
							variant="solid"
							color="positive"
							onClick={() => {
								pipelineDetailPageStore.saveTransformer(
									async () => {
										const id = toast.show({
											color: "neutral",
											timeout: 5000,
											icon: Check,
											title: "Transformer saved.",
											actionsSlot: (
												<Button
													variant="faded"
													color="inherit"
													onClick={() =>
														toast.hide(id)
													}
												>
													Dismiss
												</Button>
											),
										})
									},
								)
							}}
							disabled={
								!pipelineDetailPageStore.transformer.form
									.hasChanges
							}
							loading={pipelineDetailPageStore.isSaving}
							icon={Save}
						>
							Save Transformer
						</Button>
					</View>
				</View>
			</View>
			<FormControl
				hasError={
					pipelineDetailPageStore.transformer.form.transformer
						.hasError
				}
			>
				<View
					minHeight="300px"
					height="90dvh"
					maxHeight="calc(100dvh - 350px)"
				>
					<Suspense fallback={null}>
						<AceEditor
							ref={editor}
							onLoad={(editorInstance) => {
								editorInstance.setOption(
									"wrap",
									pipelineDetailPageStore.transformer
										.editorSettings.softWrap,
								)

								editorInstance.setOption(
									"showInvisibles",
									pipelineDetailPageStore.transformer
										.editorSettings.showInvisibles,
								)
							}}
							className={classNames({
								[css.editor]: true,
								[css.editorError]:
									pipelineDetailPageStore.transformer.form
										.transformer.hasError,
							})}
							mode="python"
							theme={colorMode === "dark" ? "dracula" : "xcode"}
							onChange={(value) => {
								pipelineDetailPageStore.transformer.form.setTransformer(
									value,
								)
							}}
							value={
								pipelineDetailPageStore.transformer.form
									.transformer.value
							}
							lineHeight="1.65"
							width="100%"
							height="100%"
							fontSize={14}
							showGutter
							tabSize={4}
							readOnly={pipelineDetailPageStore.isSaving}
							enableBasicAutocompletion
							enableLiveAutocompletion
							showPrintMargin={false}
							editorProps={{ $blockScrolling: true }}
						/>
					</Suspense>

					<FormControl.Error>
						{
							pipelineDetailPageStore.transformer.form.transformer
								.errorMessage
						}
					</FormControl.Error>
				</View>
			</FormControl>
		</View>
	)
})
