import classNames from "classnames"
import { observer } from "mobx-react"
import { createRef } from "preact"
import { Suspense } from "preact/compat"
import { useEffect } from "preact/hooks"
import { ChevronDown, Info, Settings } from "react-feather"
import {
	Button,
	Checkbox,
	CheckboxGroup,
	FormControl,
	HiddenVisually,
	Link,
	Popover,
	Select,
	Switch,
	Text,
	View,
	useTheme,
	useToast,
} from "reshaped"

import { AceEditor } from "#components/AceEditor"

import { pipelineCreatePageStore } from "../page.model"
import css from "../page.module.css"

export const TransformerStep = observer(() => {
	const { colorMode } = useTheme()
	const toast = useToast()

	const fileInput = createRef<HTMLInputElement>()
	const editor = createRef()

	useEffect(() => {
		if (editor.current) {
			editor.current.editor.setOption(
				"wrap",
				pipelineCreatePageStore.editorSettings.softWrap,
			)

			editor.current.editor.setOption(
				"showInvisibles",
				pipelineCreatePageStore.editorSettings.showInvisibles,
			)
		}
	}, [editor])

	return (
		<>
			<View gap={4}>
				<View direction="row" align="center" justify="space-between">
					<View direction="row" align="center" gap={3}>
						<Text variant="title-5">Enter Transformer</Text>
						<Link
							href="https://docs.glassflow.dev/develop/define-a-transformation-function"
							attributes={{ target: "_blank" }}
							icon={Info}
							variant="plain"
						>
							Learn more about Transformers
						</Link>
					</View>
				</View>

				<View direction="row" gap={3} align="end">
					<FormControl>
						<FormControl.Label>Template</FormControl.Label>
						<Select
							value={
								pipelineCreatePageStore.transformerForm
									.selectedTemplate
							}
							name="template"
							onChange={({ value }) => {
								if (value !== "") {
									pipelineCreatePageStore.transformerForm.selectTemplate(
										value,
									)

									let notifyAboutRequirements = false
									if (value === "aiAnomalyDetection") {
										if (
											!pipelineCreatePageStore.transformerForm.requirements.includes(
												"openai",
											)
										) {
											pipelineCreatePageStore.transformerForm.setRequirements(
												["openai"],
											)

											notifyAboutRequirements = true
										}
									} else {
										if (
											pipelineCreatePageStore
												.transformerForm.requirements
												.length > 0
										) {
											pipelineCreatePageStore.transformerForm.setRequirements(
												[],
											)

											notifyAboutRequirements = true
										}
									}

									if (notifyAboutRequirements) {
										toast.show({
											timeout: 5000,
											color: "neutral",
											size: "medium",
											title: "Updated Dependencies",
											text: "We have adjusted your dependencies to fit this template.",
										})
									}
								}
							}}
							options={[
								{
									disabled: true,
									label: "Custom",
									value: "",
								},
								{
									label: "Echo",
									value: "echo",
								},
								{
									label: "AI Anomaly Detection",
									value: "aiAnomalyDetection",
								},
								{
									label: "Mask IP Adresses",
									value: "maskIp",
								},
								{
									label: "PII Detection",
									value: "pii",
								},
								{
									label: "Filter Null Values",
									value: "removeNull",
								},
							]}
						/>
					</FormControl>

					<HiddenVisually>
						<input
							type="file"
							ref={fileInput}
							accept=".py"
							onChange={() => {
								const files = fileInput.current?.files

								if (files?.length) {
									const file = files[0]

									let reader = new FileReader()

									reader.addEventListener("load", (event) => {
										if (
											typeof event.target?.result ===
											"string"
										) {
											pipelineCreatePageStore.transformerForm.setTransformer(
												event.target.result,
											)
										}
									})

									reader.readAsText(file, "UTF-8")
								}
							}}
						/>
					</HiddenVisually>
					<Button
						variant="faded"
						color="primary"
						onClick={() => {
							fileInput.current?.click()
						}}
					>
						Upload File
					</Button>
					<Popover>
						<Popover.Trigger>
							{(attributes) => (
								<Button
									attributes={attributes}
									endIcon={ChevronDown}
									variant="faded"
								>
									Dependencies
								</Button>
							)}
						</Popover.Trigger>
						<Popover.Content>
							<View gap={5} width="230px">
								<Text variant="caption-1" wrap="balance">
									Any dependencies you select here will be
									made available to your Transformer.
									<View paddingTop={1}>
										<Link
											icon={Info}
											href="https://docs.glassflow.dev/concepts/data-transformation#python-dependencies-for-transformation"
											variant="plain"
										>
											Learn more
										</Link>
									</View>
								</Text>
								<CheckboxGroup
									name="requirements"
									value={
										pipelineCreatePageStore.transformerForm
											.requirements
									}
									onChange={({ value }) => {
										pipelineCreatePageStore.transformerForm.setRequirements(
											value,
										)
									}}
								>
									<View gap={4}>
										<Checkbox value="langchain">
											langchain
										</Checkbox>
										<Checkbox value="langchain-openai">
											langchain-openai
										</Checkbox>
										<Checkbox value="openai">
											openai
										</Checkbox>
										<Checkbox value="langchain-community">
											langchain-community
										</Checkbox>
									</View>
								</CheckboxGroup>
							</View>
						</Popover.Content>
					</Popover>
					<View grow align="end">
						<Popover position="bottom-end">
							<Popover.Trigger>
								{(attributes) => (
									<Button
										attributes={attributes}
										icon={Settings}
										variant="faded"
										color="primary"
									>
										Editor Settings
									</Button>
								)}
							</Popover.Trigger>
							<Popover.Content>
								<View direction="column" gap={4}>
									<Switch
										size="small"
										name="softWrap"
										checked={
											pipelineCreatePageStore
												.editorSettings.softWrap
										}
										onChange={({ checked }) => {
											pipelineCreatePageStore.editorSettings.setSoftWrap(
												checked,
											)
										}}
									>
										Soft Wrap
									</Switch>
									<Switch
										size="small"
										name="showInvisibles"
										checked={
											pipelineCreatePageStore
												.editorSettings.showInvisibles
										}
										onChange={({ checked }) => {
											pipelineCreatePageStore.editorSettings.setShowInvisibles(
												checked,
											)
										}}
									>
										Show Invisibles
									</Switch>
								</View>
							</Popover.Content>
						</Popover>
					</View>
				</View>

				<FormControl
					hasError={
						pipelineCreatePageStore.transformerForm.transformer
							.hasError
					}
				>
					<View
						minHeight="300px"
						height="60dvh"
						maxHeight="calc(100dvh - 450px)"
					>
						<Suspense fallback={null}>
							<AceEditor
								ref={editor}
								onLoad={(editorInstance) => {
									editorInstance.setOption(
										"wrap",
										pipelineCreatePageStore.editorSettings
											.softWrap,
									)

									editorInstance.setOption(
										"showInvisibles",
										pipelineCreatePageStore.editorSettings
											.showInvisibles,
									)
								}}
								className={classNames({
									[css.editor]: true,
									[css.editorError]:
										pipelineCreatePageStore.transformerForm
											.transformer.hasError,
								})}
								mode="python"
								theme={
									colorMode === "dark" ? "dracula" : "xcode"
								}
								onChange={(value) => {
									pipelineCreatePageStore.transformerForm.setTransformer(
										value,
									)
									pipelineCreatePageStore.transformerForm.selectTemplate(
										"",
									)
								}}
								value={
									pipelineCreatePageStore.transformerForm
										.transformer.value
								}
								lineHeight="1.65"
								width="100%"
								height="100%"
								fontSize={14}
								showGutter
								tabSize={4}
								enableBasicAutocompletion
								enableLiveAutocompletion
								showPrintMargin={false}
								editorProps={{ $blockScrolling: true }}
							/>
						</Suspense>

						<FormControl.Error>
							{
								pipelineCreatePageStore.transformerForm
									.transformer.errorMessage
							}
						</FormControl.Error>
					</View>
				</FormControl>
			</View>

			<View gap={4} direction="row" paddingTop={6}>
				<View.Item columns={6}>
					<View align="end">
						<Button
							color="neutral"
							variant="faded"
							onClick={() => {
								pipelineCreatePageStore.previousStep()
							}}
						>
							Previous Step
						</Button>
					</View>
				</View.Item>

				<View.Item columns={6}>
					<View align="start">
						<Button
							color="primary"
							onClick={() => {
								pipelineCreatePageStore.nextStep()
							}}
						>
							Next Step
						</Button>
					</View>
				</View.Item>
			</View>
		</>
	)
})
