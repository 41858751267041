import { observer } from "mobx-react"
import { Moon, RefreshCw, Sun } from "react-feather"
import {
	Card,
	HiddenVisually,
	Icon,
	Radio,
	RadioGroup,
	Text,
	Theme,
	View,
} from "reshaped"

import { appStore } from "#app"

import css from "./page.module.css"

export const Settings = observer(() => {
	if (!appStore.auth.isAuthenticated) {
		throw Error()
	}

	return (
		<View gap={3} paddingTop={4}>
			<View paddingBottom={1}>
				<Text variant="title-3">Settings</Text>
			</View>

			<View gap={3}>
				<Text variant="title-6">Appearance</Text>
				<RadioGroup
					name="themePreference"
					value={appStore.userSettings.theme}
					onChange={(event) => {
						appStore.userSettings.setTheme({
							theme: event.value as
								| "automatic"
								| "dark"
								| "light",
							persistSettings: true,
						})
					}}
				>
					<View
						gap={3}
						direction="row"
						align="stretch"
						className={css.appearanceRadio}
					>
						<Theme colorMode="dark">
							<View.Item columns={4}>
								<Card
									as="label"
									height="100%"
									selected={
										appStore.userSettings.theme === "dark"
									}
								>
									<View gap={2} align="start">
										<HiddenVisually>
											<Radio value="dark" />
										</HiddenVisually>

										<View
											direction="row"
											align="center"
											gap={2}
										>
											<Icon size={4} svg={<Moon />} />
											<Text
												variant="body-2"
												weight="medium"
											>
												Dark
											</Text>
										</View>
										<Text variant="caption-1">
											Always render the app in dark mode,
											no matter what the Operating System
											setting is.
										</Text>
									</View>
								</Card>
							</View.Item>
						</Theme>

						<Theme colorMode={appStore.systemTheme}>
							<View.Item columns={4}>
								<Card
									as="label"
									height="100%"
									selected={
										appStore.userSettings.theme ===
										"automatic"
									}
								>
									<View gap={3} align="start">
										<HiddenVisually>
											<Radio value="automatic" />
										</HiddenVisually>
										<View gap={2} align="start">
											<View
												direction="row"
												align="center"
												gap={2}
											>
												<Icon
													size={4}
													svg={<RefreshCw />}
												/>
												<Text
													variant="body-2"
													weight="bold"
												>
													Automatic
												</Text>
											</View>
											<Text variant="caption-1">
												Automatically adjust to the
												Operating System setting.
											</Text>
										</View>
									</View>
								</Card>
							</View.Item>
						</Theme>

						<Theme colorMode="light">
							<View.Item columns={4}>
								<Card
									as="label"
									height="100%"
									selected={
										appStore.userSettings.theme === "light"
									}
								>
									<View gap={3} align="start">
										<HiddenVisually>
											<Radio value="light" />
										</HiddenVisually>
										<View gap={2} align="start">
											<View
												direction="row"
												align="center"
												gap={2}
											>
												<Icon size={4} svg={<Sun />} />
												<Text
													variant="body-2"
													weight="medium"
												>
													Light
												</Text>
											</View>
											<Text variant="caption-1">
												Always render the app in light
												mode, no matter what the
												Operating System setting is.
											</Text>
										</View>
									</View>
								</Card>
							</View.Item>
						</Theme>
					</View>
				</RadioGroup>
			</View>
		</View>
	)
})
