import * as Fathom from "fathom-client"
import { Observer, observer } from "mobx-react"
import type React from "preact/compat"
import {
	BookOpen,
	ChevronDown,
	LogOut,
	Mail,
	Settings,
	Slack,
	User,
} from "react-feather"
import { Button, DropdownMenu, Link, Skeleton, Text, View } from "reshaped"

import { appStore } from "#app"
import { getLinkProps, router, useAllRoutes } from "#router"

import Logo from "./logo.svg?react"

export const Navigation = observer(() => {
	const route = useAllRoutes()

	return (
		<View align="center" direction="row">
			<View
				gap={4}
				align="center"
				direction="row"
				divided
				paddingStart={3}
			>
				<View.Item>
					<Link
						href={router.Home()}
						onClick={
							getLinkProps(router.PipelinesList({ page: "1" }))
								.onClick
						}
						attributes={{ ariaLabel: "Go to Home" }}
					>
						<Logo
							height="18px"
							style={{
								color: "var(--rs-color-foreground-primary)",
							}}
						/>
					</Link>
				</View.Item>

				<View.Item gapBefore={3}>
					<View gap={2} direction="row">
						<Button
							variant="ghost"
							as="a"
							href={router.PipelinesList()}
							onClick={
								getLinkProps(
									router.PipelinesList({ page: "1" }),
								).onClick
							}
							color={
								route?.name.startsWith("Pipeline")
									? "primary"
									: "neutral"
							}
						>
							Pipelines
						</Button>
						<Button
							variant="ghost"
							as="a"
							href={router.Spaces()}
							onClick={
								getLinkProps(router.Spaces({ page: "1" }))
									.onClick
							}
							color={
								getLinkProps(router.Spaces()).active
									? "primary"
									: "neutral"
							}
						>
							Spaces
						</Button>
					</View>
				</View.Item>
			</View>

			<View direction="row" gap={3} justify="end" grow>
				<Button
					variant="faded"
					color="neutral"
					as="a"
					href="https://docs.glassflow.dev"
					attributes={{ target: "_blank" }}
					icon={BookOpen}
					onClick={() => {
						Fathom.trackEvent("Header:Open Docs")
					}}
				>
					Documentation
				</Button>
				<DropdownMenu position="bottom-end">
					<DropdownMenu.Trigger>
						{(attributes) => {
							return (
								<Observer>
									{() => {
										let content: string | React.JSX.Element

										if (appStore.profile) {
											content = appStore.profile.name
										} else {
											content = (
												<Skeleton
													width={25}
													height={4}
												/>
											)
										}

										return (
											<View>
												<Button
													fullWidth
													variant="faded"
													color="primary"
													attributes={{
														...attributes,
														ariaLabel: "User Menu",
													}}
													endIcon={ChevronDown}
												>
													{content}
												</Button>
											</View>
										)
									}}
								</Observer>
							)
						}}
					</DropdownMenu.Trigger>
					<DropdownMenu.Content>
						<DropdownMenu.Section>
							<DropdownMenu.Item
								as="a"
								href={router.Profile()}
								onClick={getLinkProps(router.Profile()).onClick}
								selected={getLinkProps(router.Profile()).active}
								icon={User}
							>
								Profile
							</DropdownMenu.Item>
							<DropdownMenu.Item
								as="a"
								href={router.Settings()}
								onClick={
									getLinkProps(router.Settings()).onClick
								}
								selected={
									getLinkProps(router.Settings()).active
								}
								icon={Settings}
							>
								Settings
							</DropdownMenu.Item>
						</DropdownMenu.Section>
						<DropdownMenu.Section>
							<DropdownMenu.Item
								as="a"
								href="https://join.slack.com/t/glassflowhub/shared_invite/zt-2g3s6nhci-bb8cXP9g9jAQ942gHP5tqg"
								attributes={{ target: "_blank" }}
								icon={Slack}
							>
								Join Slack
							</DropdownMenu.Item>
							<DropdownMenu.Item
								as="a"
								attributes={{ target: "_blank" }}
								href={`mailto:help@glassflow.dev?subject=User Feedback | Web v${APP_VERSION}`}
								icon={Mail}
								onClick={() => {
									Fathom.trackEvent("Header:Send Feedback")
								}}
							>
								<Text>Send Feedback</Text>
								<Text color="neutral-faded" variant="caption-2">
									help@glassflow.dev
								</Text>
							</DropdownMenu.Item>
						</DropdownMenu.Section>
						<DropdownMenu.Section>
							<DropdownMenu.Item
								color="critical"
								onClick={() => {
									appStore.logout()
								}}
								icon={LogOut}
							>
								Logout
							</DropdownMenu.Item>
						</DropdownMenu.Section>
					</DropdownMenu.Content>
				</DropdownMenu>
			</View>
		</View>
	)
})
