import { observer } from "mobx-react"
import { AlertCircle, Check } from "react-feather"
import {
	ActionBar,
	Alert,
	Button,
	FormControl,
	Hidden,
	Modal,
	TextField,
	View,
	useToast,
} from "reshaped"

import { isApiError } from "#api"
import { modalsStore } from "#modals"

export const CreateSpaceModal = observer(() => {
	const createSpaceModalStore = modalsStore.createSpace

	const toast = useToast()
	let alert: React.JSX.Element | null = null

	const createSpace = async () => {
		if (await createSpaceModalStore.createSpace()) {
			const id = toast.show({
				color: "neutral",
				timeout: 5000,
				icon: Check,
				title: `"${createSpaceModalStore.form.name}" created.`,
				actionsSlot: (
					<Button
						variant="faded"
						color="inherit"
						onClick={() => toast.hide(id)}
					>
						Dismiss
					</Button>
				),
			})
		}
	}

	if (createSpaceModalStore.error) {
		alert = (
			<Alert
				color="critical"
				icon={AlertCircle}
				title="Something went wrong. Please try again."
			/>
		)
	}

	return (
		<Modal
			className="modal"
			active={createSpaceModalStore.isOpen}
			padding={0}
			size="500px"
			blurredOverlay
		>
			<View padding={4} gap={4}>
				<Modal.Title>Create Space</Modal.Title>

				<form
					onSubmit={(event) => {
						event.stopImmediatePropagation()
						event.preventDefault()
						createSpace()
					}}
				>
					<FormControl hasError={createSpaceModalStore.form.hasError}>
						<FormControl.Label>Name</FormControl.Label>
						<TextField
							name="name"
							value={createSpaceModalStore.form.name}
							onChange={(event) => {
								createSpaceModalStore.form.setName(event.value)
							}}
							inputAttributes={{
								"data-1p-ignore": true,
							}}
						/>
						<FormControl.Error>
							{createSpaceModalStore.form.errorMessage}
						</FormControl.Error>
					</FormControl>
					<Hidden hide>
						<button type="submit" />
					</Hidden>
				</form>

				{alert}
			</View>
			<ActionBar>
				<View direction="row" gap={4} justify="end">
					<Button
						disabled={createSpaceModalStore.isCreating}
						color="neutral"
						onClick={() => {
							createSpaceModalStore.closeModal()
						}}
					>
						Cancel
					</Button>
					<Button
						color="positive"
						loading={createSpaceModalStore.isCreating}
						onClick={() => createSpace()}
					>
						Create Space
					</Button>
				</View>
			</ActionBar>
		</Modal>
	)
})
