import { AnimatePresence, easeOut, m, useReducedMotion } from "framer-motion"
import { observer } from "mobx-react"
import { Suspense } from "preact/compat"
import { HiddenVisually, Stepper, Text, View } from "reshaped"
import { match } from "ts-pattern"

import { appStore } from "#app"
import { AceEditor } from "#components/AceEditor"

import { pipelineCreatePageStore } from "./page.model"

import { ConfirmationStep } from "./steps/confirmation"
import { DataSinkStep } from "./steps/dataSink"
import { DataSourceStep } from "./steps/dataSource"
import { SetupStep } from "./steps/setup"
import { TransformerStep } from "./steps/transformer"

export const PipelineCreate = observer(() => {
	if (!appStore.auth.isAuthenticated) {
		throw Error()
	}

	return (
		<View paddingTop={4}>
			{/* Trigger the loading of the lazy Ace Chunk when we enter the page, not just when the transformation step is shown. */}
			<HiddenVisually>
				<Suspense fallback={null}>
					<AceEditor mode="python" />
				</Suspense>
			</HiddenVisually>
			{/* End of Trigger */}
			<View gap={9} paddingBottom={8}>
				<Text variant="title-3">Create Pipeline</Text>

				<Stepper
					activeId={pipelineCreatePageStore.activeStepIndex}
					direction="row"
				>
					<Stepper.Item
						completed={pipelineCreatePageStore.activeStepIndex > 0}
						title="Setup"
					/>
					<Stepper.Item
						completed={pipelineCreatePageStore.activeStepIndex > 1}
						title="Data Source"
					/>
					<Stepper.Item
						completed={pipelineCreatePageStore.activeStepIndex > 2}
						title="Transformer"
					/>
					<Stepper.Item
						completed={pipelineCreatePageStore.activeStepIndex > 3}
						title="Data Sink"
					/>
					<Stepper.Item completed={false} title="Confirm" />
				</Stepper>
			</View>
			<AnimatePresence
				mode="wait"
				custom={pipelineCreatePageStore.lastStepDirection}
			>
				{match(pipelineCreatePageStore.activeStep)
					.with("Setup", (name) => {
						return (
							<Step key={name}>
								<SetupStep />
							</Step>
						)
					})
					.with("Data Source", (name) => {
						return (
							<Step key={name}>
								<DataSourceStep />
							</Step>
						)
					})
					.with("Transformer", (name) => {
						return (
							<Step key={name}>
								<TransformerStep />
							</Step>
						)
					})
					.with("Data Sink", (name) => {
						return (
							<Step key={name}>
								<DataSinkStep />
							</Step>
						)
					})
					.with("Confirmation", (name) => {
						return (
							<Step key={name} confirmationStep>
								<ConfirmationStep />
							</Step>
						)
					})
					.exhaustive()}
			</AnimatePresence>
		</View>
	)
})

const Step: React.FunctionComponent<{
	key: string
	confirmationStep?: boolean
	successStep?: boolean
}> = observer((props) => {
	const prefersReducedMotion = useReducedMotion()

	let xOffset = 20

	if (prefersReducedMotion) {
		xOffset = 0
	}

	type Direction = "Initial" | "Forwards" | "Backwards"

	const variants = {
		enter: (direction: Direction) => {
			let x: number

			if (direction === "Forwards") {
				x = xOffset
			} else if (direction === "Backwards") {
				x = -xOffset
			} else {
				x = 0
			}

			if (props.successStep) {
				x = 0
			}

			return { opacity: 0, x: x }
		},
		visible: {
			opacity: 1,
			x: 0,
		},
		exit: (direction: Direction) => {
			let x: number

			if (direction === "Forwards") {
				x = -xOffset
			} else if (direction === "Backwards") {
				x = xOffset
			} else {
				x = 0
			}

			if (props.confirmationStep) {
				x = 0
			}

			return {
				opacity: 0,
				x: x,
			}
		},
	}

	return (
		<m.div
			key={props.key}
			custom={pipelineCreatePageStore.lastStepDirection}
			variants={variants}
			initial="enter"
			animate="visible"
			exit="exit"
			transition={{
				ease: easeOut,
				duration: props.successStep ? 0.3 : 0.15,
			}}
		>
			{props.children}
		</m.div>
	)
})
