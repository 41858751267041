import { makeApi, Zodios, type ZodiosOptions } from "@zodios/core";
import { z } from "zod";

const SignUp = z
  .object({ access_token: z.string(), id_token: z.string() })
  .passthrough();
const CreateOrganization = z.object({ name: z.string() }).passthrough();
const Organization = CreateOrganization.and(
  z.object({ id: z.string() }).passthrough()
);
const Profile = z
  .object({
    id: z.string(),
    home_organization: Organization,
    name: z.string(),
    email: z.string(),
    provider: z.string(),
    external_settings: z.union([
      z.null(),
      z.object({}).partial().passthrough(),
    ]),
  })
  .passthrough();
const Error = z.object({ detail: z.string() }).passthrough();
const PaginationResponse = z
  .object({ total_amount: z.number().int() })
  .passthrough();
const OrganizationScope = Organization.and(
  z.object({ role: z.string() }).passthrough()
);
const OrganizationScopes = z.array(OrganizationScope);
const ListOrganizationScopes = PaginationResponse.and(
  z.object({ organizations: OrganizationScopes }).passthrough()
);
const CreateSpace = z.object({ name: z.string() }).passthrough();
const Space = CreateSpace.and(
  z.object({ id: z.string(), created_at: z.string() }).passthrough()
);
const SpaceScope = Space.and(
  z.object({ permission: z.string() }).passthrough()
);
const SpaceScopes = z.array(SpaceScope);
const ListSpaceScopes = PaginationResponse.and(
  z.object({ spaces: SpaceScopes }).passthrough()
);
const UpdateSpace = z.object({ name: z.string() }).passthrough();
const BasePipeline = z
  .object({
    name: z.string(),
    space_id: z.string(),
    metadata: z.union([z.null(), z.object({}).partial().passthrough()]),
  })
  .passthrough();
const Pipeline = BasePipeline.and(
  z.object({ id: z.string(), created_at: z.string() }).passthrough()
);
const SpacePipeline = Pipeline.and(
  z.object({ space_name: z.string() }).passthrough()
);
const SpacePipelines = z.array(SpacePipeline);
const ListPipelines = PaginationResponse.and(
  z.object({ pipelines: SpacePipelines }).passthrough()
);
const SourceConnector = z.union([
  z.null(),
  z
    .object({
      kind: z.literal("google_pubsub"),
      config: z
        .object({
          project_id: z.string(),
          subscription_id: z.string(),
          credentials_json: z.string(),
        })
        .passthrough(),
    })
    .passthrough(),
  z
    .object({
      kind: z.literal("amazon_sqs"),
      config: z
        .object({
          queue_url: z.string(),
          aws_region: z.string(),
          aws_access_key: z.string(),
          aws_secret_key: z.string(),
        })
        .passthrough(),
    })
    .passthrough(),
]);
const SinkConnector = z.union([
  z.null(),
  z
    .object({
      kind: z.literal("webhook"),
      config: z
        .object({
          url: z.string(),
          method: z.enum(["GET", "POST", "PUT", "PATCH", "DELETE"]),
          headers: z.record(z.string()),
        })
        .passthrough(),
    })
    .passthrough(),
]);
const CreatePipeline = BasePipeline.and(
  z
    .object({
      transformation_function: z.string(),
      transformation_requirements: z.array(z.string()),
      requirements_txt: z.string(),
    })
    .partial()
    .passthrough()
).and(
  z
    .object({
      source_connector: SourceConnector,
      sink_connector: SinkConnector,
    })
    .partial()
    .passthrough()
);
const GetDetailedSpacePipeline = SpacePipeline.and(
  z
    .object({
      source_connector: SourceConnector,
      sink_connector: SinkConnector,
    })
    .passthrough()
);
const UpdatePipeline = z
  .object({
    name: z.string(),
    transformation_function: z.string().optional(),
    transformation_requirements: z.array(z.string()).optional(),
    requirements_txt: z.string().optional(),
    metadata: z
      .union([z.null(), z.object({}).partial().passthrough()])
      .optional(),
    source_connector: SourceConnector.optional(),
    sink_connector: SinkConnector.optional(),
  })
  .passthrough();
const SourceFile = z
  .object({ name: z.string(), content: z.string() })
  .passthrough();
const SourceFiles = z.array(SourceFile);
const SeverityCode = z.number();
const FunctionLogEntry = z
  .object({
    level: z.string(),
    severity_code: SeverityCode.int(),
    timestamp: z.string(),
    payload: z.object({ message: z.string() }).passthrough(),
  })
  .passthrough();
const FunctionLogs = z.array(FunctionLogEntry);
const EventContext = z
  .object({
    request_id: z.string(),
    external_id: z.string().optional(),
    receive_time: z.string(),
  })
  .passthrough();
const ConsumeEvent = z
  .object({
    req_id: z.string().optional(),
    receive_time: z.string().optional(),
    payload: z.object({}).partial().passthrough(),
    event_context: EventContext,
    status: z.string(),
    response: z.object({}).partial().passthrough(),
  })
  .passthrough();
const CreateAccessToken = z.object({ name: z.string() }).passthrough();
const AccessToken = CreateAccessToken.and(
  z
    .object({ id: z.string(), token: z.string(), created_at: z.string() })
    .passthrough()
);
const AccessTokens = z.array(AccessToken);
const ListAccessTokens = PaginationResponse.and(
  z.object({ access_tokens: AccessTokens }).passthrough()
);
const SeverityCodeInput = z.union([
  z.literal(100),
  z.literal(200),
  z.literal(400),
  z.literal(500),
]);

export const schemas = {
  SignUp,
  CreateOrganization,
  Organization,
  Profile,
  Error,
  PaginationResponse,
  OrganizationScope,
  OrganizationScopes,
  ListOrganizationScopes,
  CreateSpace,
  Space,
  SpaceScope,
  SpaceScopes,
  ListSpaceScopes,
  UpdateSpace,
  BasePipeline,
  Pipeline,
  SpacePipeline,
  SpacePipelines,
  ListPipelines,
  SourceConnector,
  SinkConnector,
  CreatePipeline,
  GetDetailedSpacePipeline,
  UpdatePipeline,
  SourceFile,
  SourceFiles,
  SeverityCode,
  FunctionLogEntry,
  FunctionLogs,
  EventContext,
  ConsumeEvent,
  CreateAccessToken,
  AccessToken,
  AccessTokens,
  ListAccessTokens,
  SeverityCodeInput,
};

const endpoints = makeApi([
  {
    method: "get",
    path: "/organizations",
    alias: "listOrganizations",
    requestFormat: "json",
    parameters: [
      {
        name: "page_size",
        type: "Query",
        schema: z.number().int().gte(0).optional().default(50),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).optional().default(1),
      },
    ],
    response: ListOrganizationScopes,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/pipelines",
    alias: "listPipelines",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "space_id",
        type: "Query",
        schema: z.array(z.string()).optional(),
      },
      {
        name: "page_size",
        type: "Query",
        schema: z.number().int().gte(0).optional().default(50),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: "order_by",
        type: "Query",
        schema: z.enum(["asc", "desc"]).optional().default("asc"),
      },
    ],
    response: ListPipelines,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/pipelines",
    alias: "createPipeline",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreatePipeline,
      },
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "echo-function",
        type: "Query",
        schema: z.boolean().optional().default(false),
      },
    ],
    response: Pipeline.and(
      z.object({ access_token: z.string() }).passthrough()
    ),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/pipelines/:pipeline_id",
    alias: "getPipeline",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: GetDetailedSpacePipeline,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "put",
    path: "/pipelines/:pipeline_id",
    alias: "updatePipeline",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: UpdatePipeline,
      },
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: SpacePipeline,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "delete",
    path: "/pipelines/:pipeline_id",
    alias: "deletePipeline",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/pipelines/:pipeline_id/access_tokens",
    alias: "listAccessTokens",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "page_size",
        type: "Query",
        schema: z.number().int().gte(0).optional().default(50),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).optional().default(1),
      },
    ],
    response: ListAccessTokens,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/pipelines/:pipeline_id/access_tokens",
    alias: "createAccessToken",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ name: z.string() }).passthrough(),
      },
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: AccessToken,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "put",
    path: "/pipelines/:pipeline_id/access_tokens/:token_id",
    alias: "updateAccessToken",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ name: z.string() }).passthrough(),
      },
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "token_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: AccessToken,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "delete",
    path: "/pipelines/:pipeline_id/access_tokens/:token_id",
    alias: "deleteAccessToken",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "token_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/pipelines/:pipeline_id/functions/main/artifacts",
    alias: "uploadFunctionArtifacts",
    requestFormat: "form-data",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z
          .object({
            file: z.instanceof(File),
            requirementsTxt: z.instanceof(File).optional(),
            requirements: z.string().optional(),
          })
          .passthrough(),
      },
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 425,
        description: `Still in progress`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/pipelines/:pipeline_id/functions/main/logs",
    alias: "getFunctionLogs",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "page_size",
        type: "Query",
        schema: z.number().int().optional().default(10),
      },
      {
        name: "page_token",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "severity_code",
        type: "Query",
        schema: z
          .union([
            z.literal(100),
            z.literal(200),
            z.literal(400),
            z.literal(500),
          ])
          .optional(),
      },
      {
        name: "start_time",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "end_time",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({ logs: FunctionLogs, next: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/pipelines/:pipeline_id/functions/main/source",
    alias: "getFunctionSource",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.object({ files: SourceFiles }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/pipelines/:pipeline_id/topics/:topic/events",
    alias: "pushEvent",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "Authorization",
        type: "Header",
        schema: z.string().optional(),
      },
      {
        name: "X-PIPELINE-ACCESS-TOKEN",
        type: "Header",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_access_token",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "topic",
        type: "Path",
        schema: z.literal("input"),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/pipelines/:pipeline_id/topics/:topic/events/consume",
    alias: "consumeEvent",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "topic",
        type: "Path",
        schema: z.enum(["output", "failed"]),
      },
      {
        name: "Authorization",
        type: "Header",
        schema: z.string().optional(),
      },
      {
        name: "X-PIPELINE-ACCESS-TOKEN",
        type: "Header",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_access_token",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: ConsumeEvent,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 429,
        description: `Too many requests`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/pipelines/:pipeline_id/topics/:topic/events/preview",
    alias: "previewEvents",
    description: `The endpoint gets the last element and tries to lookup for other &#x27;num&#x27; messages if exists.
`,
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "topic",
        type: "Path",
        schema: z.enum(["input", "output", "failed"]),
      },
      {
        name: "num",
        type: "Query",
        schema: z.number().int().optional(),
      },
    ],
    response: z.array(z.object({}).partial().passthrough()),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/spaces",
    alias: "listSpaces",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "page_size",
        type: "Query",
        schema: z.number().int().gte(0).optional().default(50),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: "order_by",
        type: "Query",
        schema: z.enum(["asc", "desc"]).optional().default("asc"),
      },
    ],
    response: ListSpaceScopes,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/spaces",
    alias: "createSpace",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ name: z.string() }).passthrough(),
      },
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: Space,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/spaces/:space_id",
    alias: "getSpace",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "space_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: Space,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "put",
    path: "/spaces/:space_id",
    alias: "updateSpace",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ name: z.string() }).passthrough(),
      },
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "space_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: Space,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "delete",
    path: "/spaces/:space_id",
    alias: "deleteSpace",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "space_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 409,
        description: `Space is not empty`,
        schema: z
          .object({ msg: z.string(), existed_pipeline_id: z.string() })
          .partial()
          .passthrough(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/users",
    alias: "signUp",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: SignUp,
      },
    ],
    response: Profile,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Already exists`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/users/me",
    alias: "getProfile",
    requestFormat: "json",
    response: Profile,
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/users/me/external_settings",
    alias: "SaveUserExternalSettings",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
]);

export const api = new Zodios("https://api.glassflow.dev/v1", endpoints);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}
