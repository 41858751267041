import classNames from "classnames"
import { AnimatePresence, LazyMotion, domAnimation } from "framer-motion"
import { autorun } from "mobx"
import { observer } from "mobx-react"
import { Hidden, Reshaped, Text, View, useTheme } from "reshaped"
import { P, match } from "ts-pattern"

import { AnimatedVerticalPresence } from "#components/AnimatedVerticalPresence"
import { Navigation } from "#components/Navigation"
import { metricsApi } from "#metrics"
import { Modals } from "#modals"

import "../themes/glassflow/theme.css"
import "./css/fonts.css"
import "./css/global.css"
import "./css/reset.css"

import { appStore } from "./app.model"
import css from "./app.module.css"

import { router, useAllRoutes } from "../router"

import { NotFound } from "../pages/NotFound/page"
import { PipelineCreate } from "../pages/PipelineCreate/page"
import { pipelineCreatePageStore } from "../pages/PipelineCreate/page.model"
import { PipelineDetail } from "../pages/PipelineDetail/page"
import { pipelineDetailPageStore } from "../pages/PipelineDetail/page.model"
import { Pipelines } from "../pages/Pipelines/page"
import { pipelinesPageStore } from "../pages/Pipelines/page.model"
import { Profile } from "../pages/Profile/page"
import { Settings } from "../pages/Settings/page"
import { Spaces } from "../pages/Spaces/page"
import { spacesPageStore } from "../pages/Spaces/page.model"
import { Unauthenticated } from "../pages/Unauthenticated/page"

import Logo from "./logo.svg?react"

export const appId = `web-ui/${APP_VERSION}`

const ColorManager = () => {
	const { setColorMode } = useTheme()

	autorun(() => {
		setColorMode(appStore.resolvedTheme())
	})

	return null
}

export * from "./app.model"

export const App = observer(() => {
	const route = useAllRoutes()

	let content: preact.JSX.Element

	if (appStore.auth.isAuthenticated) {
		content = (
			<>
				<Hidden hide={{ s: false, m: true }}>
					<div class={css.mobileOverlay}>
						<Logo width="250px" class={css.logo} />

						<View gap={4} align="center">
							<Text align="center" variant="title-2">
								Please use a larger Screen
							</Text>
							<Text
								align="center"
								variant="body-1"
								attributes={{ style: { textWrap: "pretty" } }}
							>
								The GlassFlow Web App only supports screens that
								are at least 768px wide, at this time.
							</Text>
						</View>
					</div>
				</Hidden>

				<Hidden hide={{ s: true, m: false }}>
					<AnimatePresence>
						<AnimatedVerticalPresence key="Navigation">
							<View align="center" className={css.header}>
								<View
									width="100dvw"
									minWidth="768px"
									maxWidth="800px"
									paddingBlock={3}
									paddingInline={5}
								>
									<Navigation />
								</View>
							</View>
						</AnimatedVerticalPresence>
					</AnimatePresence>

					<View align="center">
						<AnimatePresence>
							<AnimatedVerticalPresence
								key="INITIAL"
								config={{ delay: 0.3 }}
							>
								<View
									width="100dvw"
									minWidth="768px"
									maxWidth="800px"
									paddingBlock={3}
									paddingInline={5}
								>
									<AnimatePresence
										mode="wait"
										initial={false}
									>
										{match(route)
											.with(router.P.Home(P._), () => {
												router.replace("PipelinesList")
												return null
											})
											.with(
												router.P.PipelinesList(P._),
												({ params, name }) => {
													let page = 1

													if (params.page) {
														page = Number.parseInt(
															params.page,
														)
													}

													pipelinesPageStore.getPipelinesPage(
														{
															page,
														},
													)

													return (
														<AnimatedVerticalPresence
															key={name}
														>
															<Pipelines />
														</AnimatedVerticalPresence>
													)
												},
											)
											.with(
												P.union(
													router.P.PipelinesDetailDetails(
														P._,
													),
													router.P.PipelinesDetailTransformer(
														P._,
													),
													router.P.PipelinesDetailTokens(
														P._,
													),
													router.P.PipelinesDetailLogs(
														P._,
													),
												),

												({ params, name }) => {
													pipelineDetailPageStore.getPipeline(
														{
															pipelineId:
																params.pipelineId,
														},
													)

													return (
														<AnimatedVerticalPresence
															key={name}
														>
															<PipelineDetail />
														</AnimatedVerticalPresence>
													)
												},
											)
											.with(
												router.P.PipelinesCreate(P._),
												({ name }) => {
													pipelineCreatePageStore.resetPage()
													pipelineCreatePageStore.getSpaces()

													if (
														appStore.profile &&
														location.origin ===
															"https://app.glassflow.dev"
													) {
														metricsApi.postMetricEvents(
															[
																{
																	name: "pipeline_creation_started",
																	created_at:
																		new Date().toISOString(),
																	data: {
																		user_id:
																			appStore
																				.profile
																				.id,
																		email: appStore
																			.profile
																			.email,
																		timestamp:
																			new Date().toISOString(),
																	},
																	metadata: {
																		source: appId,
																	},
																},
															],
															{},
														)
													}

													return (
														<AnimatedVerticalPresence
															key={name}
														>
															<PipelineCreate />
														</AnimatedVerticalPresence>
													)
												},
											)

											.with(
												router.P.Spaces(P._),
												({ params, name }) => {
													let page = 1

													if (params.page) {
														page = Number.parseInt(
															params.page,
														)
													}

													spacesPageStore.getSpacesPage(
														{
															page,
														},
													)

													return (
														<AnimatedVerticalPresence
															key={name}
														>
															<Spaces />
														</AnimatedVerticalPresence>
													)
												},
											)
											.with(
												router.P.Profile(P._),
												({ name }) => {
													return (
														<AnimatedVerticalPresence
															key={name}
														>
															<Profile />
														</AnimatedVerticalPresence>
													)
												},
											)
											.with(
												router.P.Settings(P._),
												({ name }) => {
													return (
														<AnimatedVerticalPresence
															key={name}
														>
															<Settings />
														</AnimatedVerticalPresence>
													)
												},
											)
											.otherwise(() => {
												return (
													<AnimatedVerticalPresence key="NotFound">
														<NotFound />
													</AnimatedVerticalPresence>
												)
											})}
									</AnimatePresence>
								</View>
							</AnimatedVerticalPresence>
						</AnimatePresence>
					</View>
				</Hidden>
			</>
		)
	} else {
		content = <Unauthenticated />
	}

	return (
		<Reshaped theme="glassflow">
			<LazyMotion features={domAnimation} strict>
				<View>
					{content}
					<div
						class={classNames(
							css.detectingAuthenticationStateOverlay,
							{
								[css.stateDetected]:
									!appStore.auth.isDetectingState,
							},
						)}
					/>
					<ColorManager />
					<Modals />
				</View>
			</LazyMotion>
		</Reshaped>
	)
})
