import { AnimatePresence, m } from "framer-motion"
import { observer } from "mobx-react"
import { Suspense } from "preact/compat"
import { ChevronDown, Edit2, Trash } from "react-feather"
import {
	Breadcrumbs,
	Button,
	DropdownMenu,
	HiddenVisually,
	Skeleton,
	Tabs,
	Text,
	View,
} from "reshaped"
import { P, match } from "ts-pattern"

import { AceEditor } from "#components/AceEditor"
import { modalsStore } from "#modals"
import { getLinkProps, router } from "#router"

import { pipelineDetailPageStore } from "./page.model"

import { AccessTokens } from "./components/AccessTokens"
import { Details } from "./components/Details"
import { Logs } from "./components/Logs"
import { TransformerFunction } from "./components/TransformerFunction"

const Header = observer(() => {
	return (
		<View direction="column">
			<Breadcrumbs separator="/" color="primary">
				<Breadcrumbs.Item
					href={router.PipelinesList()}
					onClick={getLinkProps(router.PipelinesList()).onClick}
				>
					<Text variant="caption-1">Pipelines</Text>
				</Breadcrumbs.Item>
				<Breadcrumbs.Item />
			</Breadcrumbs>
			<View
				direction="row"
				gap={1}
				align="center"
				justify="space-between"
			>
				<View direction="row" gap={4} align="center">
					{pipelineDetailPageStore.pipeline ? (
						<Text variant="title-2" weight="medium">
							{pipelineDetailPageStore.pipeline.name}
						</Text>
					) : (
						<View paddingTop={2}>
							<Skeleton width={50} height={9} />
						</View>
					)}
				</View>
				<DropdownMenu position="bottom-end">
					<DropdownMenu.Trigger>
						{(attributes) => (
							<Button
								color="primary"
								variant="faded"
								endIcon={ChevronDown}
								attributes={attributes}
								disabled={!pipelineDetailPageStore.pipeline}
							>
								Actions
							</Button>
						)}
					</DropdownMenu.Trigger>
					<DropdownMenu.Content>
						<DropdownMenu.Section>
							<DropdownMenu.Item
								icon={Edit2}
								onClick={() => {
									let pipeline =
										pipelineDetailPageStore.pipeline
									if (pipeline) {
										modalsStore.renamePipeline.openModal(
											pipeline.id,
											pipeline.name,
											async () => {
												pipelineDetailPageStore.refreshPipeline()
											},
										)
									}
								}}
							>
								Rename
							</DropdownMenu.Item>
						</DropdownMenu.Section>

						<DropdownMenu.Section>
							<DropdownMenu.Item
								color="critical"
								icon={Trash}
								onClick={() => {
									let pipeline =
										pipelineDetailPageStore.pipeline
									if (pipeline) {
										modalsStore.deletePipeline.openModal(
											pipeline.id,
											pipeline.name,
											async () => {
												router.push("PipelinesList")
											},
										)
									}
								}}
							>
								Delete Pipeline
							</DropdownMenu.Item>
						</DropdownMenu.Section>
					</DropdownMenu.Content>
				</DropdownMenu>
			</View>
		</View>
	)
})

export const PipelineDetail = observer(() => {
	const routes = router.useRoute([
		"PipelinesDetailDetails",
		"PipelinesDetailTransformer",
		"PipelinesDetailTokens",
		"PipelinesDetailLogs",
	])

	const selectedTab = match(routes)
		.with(router.P.PipelinesDetailTokens(P._), () => "tokens")
		.with(router.P.PipelinesDetailLogs(P._), () => "logs")
		.with(router.P.PipelinesDetailTransformer(P._), () => "transformer")
		.otherwise(() => "details")

	return (
		<View gap={4} paddingTop={4}>
			<Header />

			{/* Trigger the loading of the lazy Ace Chunk when we enter the page, not just when the transformation tab is shown. */}
			<HiddenVisually>
				<Suspense fallback={null}>
					<AceEditor mode="python" />
				</Suspense>
			</HiddenVisually>
			{/* End of Trigger */}

			<View gap={3}>
				<Tabs
					defaultValue={selectedTab}
					onChange={(event) => {
						if (pipelineDetailPageStore.pipeline) {
							let routeParameters = {
								pipelineId: pipelineDetailPageStore.pipeline.id,
								spaceId:
									pipelineDetailPageStore.pipeline.space_id,
							}

							match(event.value)
								.with("transformer", () => {
									history.pushState(
										{},
										"",
										router.PipelinesDetailTransformer(
											routeParameters,
										),
									)
								})
								.with("tokens", () => {
									history.pushState(
										{},
										"",
										router.PipelinesDetailTokens(
											routeParameters,
										),
									)
								})
								.with("logs", () => {
									history.pushState(
										{},
										"",
										router.PipelinesDetailLogs(
											routeParameters,
										),
									)
								})
								.otherwise(() => {
									history.pushState(
										{},
										"",
										router.PipelinesDetailDetails(
											routeParameters,
										),
									)
								})
						}
					}}
				>
					<Tabs.List>
						<Tabs.Item value="details">
							<View paddingInline={2}>Details</View>
						</Tabs.Item>
						<Tabs.Item value="transformer">
							<View paddingInline={2}>Transformer</View>
						</Tabs.Item>
						<Tabs.Item value="tokens">
							<View paddingInline={2}>Access Tokens</View>
						</Tabs.Item>
						<Tabs.Item value="logs">
							<View paddingInline={2}>Logs</View>
						</Tabs.Item>
					</Tabs.List>
					<View paddingBlock={4}>
						<AnimatePresence mode="wait">
							<Tabs.Panel value="details" key="detailsParent">
								<TabPanel key="details">
									<Details />
								</TabPanel>
							</Tabs.Panel>
							<Tabs.Panel
								value="transformer"
								key="transformerFunctionParent"
							>
								<TabPanel key="transformerFunction">
									<TransformerFunction />
								</TabPanel>
							</Tabs.Panel>
							<Tabs.Panel value="tokens" key="tokensParent">
								<TabPanel key="tokens">
									<AccessTokens />
								</TabPanel>
							</Tabs.Panel>
							<Tabs.Panel value="logs" key="logsParent">
								<TabPanel key="logs">
									<Logs />
								</TabPanel>
							</Tabs.Panel>
						</AnimatePresence>
					</View>
				</Tabs>
			</View>
		</View>
	)
})

export const TabPanel: React.FunctionComponent<{ key: string }> = observer(
	(props) => {
		return (
			<m.div
				key={props.key}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 0.4 }}
			>
				{props.children}
			</m.div>
		)
	},
)
