import { isErrorFromAlias } from "@zodios/core"
import type {
	Aliases,
	ZodiosMatchingErrorsByAlias,
} from "@zodios/core/lib/zodios.types"

import { api } from "./api"

type Api = typeof api.api

export function isApiError<Alias extends string>(
	alias: Alias extends Aliases<Api> ? Alias : Aliases<Api>,
	error: unknown,
): error is ZodiosMatchingErrorsByAlias<Api, Alias> {
	// NOTE: This is a workaround for the mismatch of our OpenAPI Spec and
	// Axios' handling of an empty response body, which results in "" rather than undefined
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	if ((error as any).response.data === "") {
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		;(error as any).response.data = undefined
	}

	return isErrorFromAlias(api.api, alias, error)
}
