import { observer } from "mobx-react"
import { Info } from "react-feather"
import {
	Button,
	Card,
	FormControl,
	HiddenVisually,
	Link,
	Radio,
	RadioGroup,
	Text,
	TextArea,
	TextField,
	View,
} from "reshaped"

import { pipelineCreatePageStore } from "../page.model"

export const DataSourceStep = observer(() => {
	let details: React.JSX.Element | null = null

	if (pipelineCreatePageStore.sourceForm.connector.type === "google_pubsub") {
		details = (
			<View gap={4} direction="column" paddingTop={6} key="google_pubsub">
				<Text variant="title-6">Connector Details</Text>

				<View direction="row" wrap gap={4}>
					<View.Item columns={6}>
						<FormControl
							hasError={
								pipelineCreatePageStore.sourceForm.connector
									.meta.projectId.hasError
							}
						>
							<FormControl.Label>Project ID</FormControl.Label>
							<TextField
								name="projectId"
								value={
									pipelineCreatePageStore.sourceForm.connector
										.meta.projectId.value
								}
								onChange={(event) => {
									if (
										pipelineCreatePageStore.sourceForm
											.connector.type === "google_pubsub"
									) {
										pipelineCreatePageStore.sourceForm.connector.meta.setProjectId(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{
									pipelineCreatePageStore.sourceForm.connector
										.meta.projectId.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>

					<View.Item columns={6}>
						<FormControl
							hasError={
								pipelineCreatePageStore.sourceForm.connector
									.meta.subscriptionId.hasError
							}
						>
							<FormControl.Label>
								Subscription ID
							</FormControl.Label>
							<TextField
								name="subscriptionId"
								value={
									pipelineCreatePageStore.sourceForm.connector
										.meta.subscriptionId.value
								}
								onChange={(event) => {
									if (
										pipelineCreatePageStore.sourceForm
											.connector.type === "google_pubsub"
									) {
										pipelineCreatePageStore.sourceForm.connector.meta.setSubscriptionId(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{
									pipelineCreatePageStore.sourceForm.connector
										.meta.subscriptionId.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>

					<View.Item columns={12}>
						<FormControl
							hasError={
								pipelineCreatePageStore.sourceForm.connector
									.meta.credentials.hasError
							}
						>
							<FormControl.Label>
								Credentials JSON
							</FormControl.Label>
							<TextArea
								name="credentials"
								resize="none"
								value={
									pipelineCreatePageStore.sourceForm.connector
										.meta.credentials.value
								}
								onChange={(event) => {
									if (
										pipelineCreatePageStore.sourceForm
											.connector.type === "google_pubsub"
									) {
										pipelineCreatePageStore.sourceForm.connector.meta.setCredentials(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{
									pipelineCreatePageStore.sourceForm.connector
										.meta.credentials.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>
				</View>
			</View>
		)
	} else if (
		pipelineCreatePageStore.sourceForm.connector.type === "amazon_sqs"
	) {
		details = (
			<View gap={4} direction="column" paddingTop={6} key="amazon_sqs">
				<Text variant="title-6">Connector Details</Text>

				<View direction="row" wrap gap={4}>
					<View.Item columns={6}>
						<FormControl
							hasError={
								pipelineCreatePageStore.sourceForm.connector
									.meta.queueUrl.hasError
							}
						>
							<FormControl.Label>Queue URL</FormControl.Label>
							<TextField
								name="queueUrl"
								value={
									pipelineCreatePageStore.sourceForm.connector
										.meta.queueUrl.value
								}
								onChange={(event) => {
									if (
										pipelineCreatePageStore.sourceForm
											.connector.type === "amazon_sqs"
									) {
										pipelineCreatePageStore.sourceForm.connector.meta.setQueueUrl(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{
									pipelineCreatePageStore.sourceForm.connector
										.meta.queueUrl.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>

					<View.Item columns={6}>
						<FormControl
							hasError={
								pipelineCreatePageStore.sourceForm.connector
									.meta.region.hasError
							}
						>
							<FormControl.Label>AWS Region</FormControl.Label>
							<TextField
								name="region"
								value={
									pipelineCreatePageStore.sourceForm.connector
										.meta.region.value
								}
								onChange={(event) => {
									if (
										pipelineCreatePageStore.sourceForm
											.connector.type === "amazon_sqs"
									) {
										pipelineCreatePageStore.sourceForm.connector.meta.setRegion(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{
									pipelineCreatePageStore.sourceForm.connector
										.meta.region.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>

					<View.Item columns={6}>
						<FormControl
							hasError={
								pipelineCreatePageStore.sourceForm.connector
									.meta.accessKey.hasError
							}
						>
							<FormControl.Label>
								AWS Access Key
							</FormControl.Label>
							<TextField
								name="accessKey"
								value={
									pipelineCreatePageStore.sourceForm.connector
										.meta.accessKey.value
								}
								onChange={(event) => {
									if (
										pipelineCreatePageStore.sourceForm
											.connector.type === "amazon_sqs"
									) {
										pipelineCreatePageStore.sourceForm.connector.meta.setAccessKey(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{
									pipelineCreatePageStore.sourceForm.connector
										.meta.accessKey.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>

					<View.Item columns={6}>
						<FormControl
							hasError={
								pipelineCreatePageStore.sourceForm.connector
									.meta.secretKey.hasError
							}
						>
							<FormControl.Label>
								AWS Secret Key
							</FormControl.Label>
							<TextField
								name="secretKey"
								value={
									pipelineCreatePageStore.sourceForm.connector
										.meta.secretKey.value
								}
								onChange={(event) => {
									if (
										pipelineCreatePageStore.sourceForm
											.connector.type === "amazon_sqs"
									) {
										pipelineCreatePageStore.sourceForm.connector.meta.setSecretKey(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{
									pipelineCreatePageStore.sourceForm.connector
										.meta.secretKey.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>
				</View>
			</View>
		)
	}

	return (
		<>
			<View paddingBottom={4} direction="row" gap={3} align="center">
				<Text variant="title-5">Select Data Source</Text>
				<Link
					href="https://docs.glassflow.dev/concepts/producer"
					attributes={{ target: "_blank" }}
					variant="plain"
					icon={Info}
				>
					Learn more about Data Sources
				</Link>
			</View>
			<View direction="row" align="stretch" gap={4}>
				<RadioGroup
					name="source"
					onChange={(event) => {
						pipelineCreatePageStore.sourceForm.setType(
							event.value as
								| "sdk"
								| "webhook"
								| "amazon_sqs"
								| "google_pubsub",
						)
					}}
				>
					<View.Item columns={6}>
						<Card
							as="label"
							height="100%"
							selected={
								pipelineCreatePageStore.sourceForm.connector
									.type === "sdk"
							}
						>
							<HiddenVisually>
								<Radio value="sdk" />
							</HiddenVisually>
							<View gap={3} direction="column" align="start">
								<Text variant="body-2" weight="medium">
									SDK
								</Text>
								<Text variant="body-3" color="neutral-faded">
									Create a custom data source using our Python
									SDK.
								</Text>
							</View>
						</Card>
					</View.Item>

					<View.Item columns={6}>
						<Card
							as="label"
							height="100%"
							selected={
								pipelineCreatePageStore.sourceForm.connector
									.type === "webhook"
							}
						>
							<HiddenVisually>
								<Radio value="webhook" />
							</HiddenVisually>
							<View gap={3} direction="column" align="start">
								<Text variant="body-2" weight="medium">
									Webhook
								</Text>
								<Text variant="body-3" color="neutral-faded">
									Publish events to GlassFlow pipeline using a
									Webhook URL.
								</Text>
							</View>
						</Card>
					</View.Item>

					<View.Item columns={6}>
						<Card
							as="label"
							height="100%"
							selected={
								pipelineCreatePageStore.sourceForm.connector
									.type === "amazon_sqs"
							}
						>
							<HiddenVisually>
								<Radio value="amazon_sqs" />
							</HiddenVisually>
							<View gap={3} direction="column" align="start">
								<Text variant="body-2" weight="medium">
									Amazon SQS
								</Text>
								<Text variant="body-3" color="neutral-faded">
									SQS lets you send, store, and receive
									messages between software components at any
									volume.
								</Text>
							</View>
						</Card>
					</View.Item>

					<View.Item columns={6}>
						<Card
							as="label"
							height="100%"
							selected={
								pipelineCreatePageStore.sourceForm.connector
									.type === "google_pubsub"
							}
						>
							<HiddenVisually>
								<Radio value="google_pubsub" />
							</HiddenVisually>
							<View gap={3} direction="column" align="start">
								<Text variant="body-2" weight="medium">
									Google Pub/Sub
								</Text>
								<Text variant="body-3" color="neutral-faded">
									Google Pub/Sub works as a messaging
									middleware for traditional service
									integration or a simple communication medium
									for modern microservices.
								</Text>
							</View>
						</Card>
					</View.Item>
				</RadioGroup>
			</View>

			{details}

			<View gap={4} direction="row" paddingTop={6}>
				<View.Item columns={6}>
					<View align="end">
						<Button
							color="neutral"
							variant="faded"
							onClick={() => {
								pipelineCreatePageStore.previousStep()
							}}
						>
							Previous Step
						</Button>
					</View>
				</View.Item>

				<View.Item columns={6}>
					<View align="start">
						<Button
							color="primary"
							onClick={() => {
								pipelineCreatePageStore.nextStep()
							}}
						>
							Next Step
						</Button>
					</View>
				</View.Item>
			</View>
		</>
	)
})
