import classNames from "classnames"
import { observer } from "mobx-react"
import { useEffect } from "preact/hooks"
import { Info } from "react-feather"
import {
	Actionable,
	Button,
	FormControl,
	Hidden,
	Link,
	Loader,
	Select,
	Text,
	TextField,
	View,
} from "reshaped"

import { modalsStore } from "#modals"

import { pipelineCreatePageStore } from "../page.model"
import css from "../page.module.css"

export const SetupStep = observer(() => {
	useEffect(() => {
		;(
			document.querySelector("input[name=pipelineName]") as
				| HTMLInputElement
				| undefined
		)?.focus()
	}, [])

	const createSpaceButton = (
		<Actionable
			onClick={() => {
				modalsStore.createSpace.openModal(async (space) => {
					await pipelineCreatePageStore.getSpaces()
					pipelineCreatePageStore.setupForm.setSpace(space.id)
				})
			}}
		>
			<Text color="primary">Create another Space</Text>
		</Actionable>
	)

	return (
		<form
			onSubmit={(event) => {
				event.stopImmediatePropagation()
				event.preventDefault()
				pipelineCreatePageStore.nextStep()
			}}
		>
			<View paddingBottom={4} align="center" justify="center" gap={1}>
				<Text variant="title-5">Setup Pipeline</Text>

				<Link
					href="https://docs.glassflow.dev/concepts/pipeline"
					attributes={{ target: "_blank" }}
					variant="plain"
					icon={Info}
				>
					Learn more about Pipelines
				</Link>
			</View>
			<View align="center">
				<View gap={6} width="50%">
					<FormControl
						hasError={
							pipelineCreatePageStore.setupForm.name.hasError
						}
					>
						<FormControl.Label>Name</FormControl.Label>
						<TextField
							name="pipelineName"
							value={pipelineCreatePageStore.setupForm.name.value}
							onChange={(event) => {
								pipelineCreatePageStore.setupForm.setName(
									event.value,
								)
							}}
							inputAttributes={{
								"data-1p-ignore": true,
							}}
						/>
						<FormControl.Error>
							{
								pipelineCreatePageStore.setupForm.name
									.errorMessage
							}
						</FormControl.Error>
					</FormControl>
					<FormControl>
						<View
							direction="row"
							align="center"
							gap={2}
							className={classNames({
								[css.spaceLabel]:
									pipelineCreatePageStore.isFetchingSpaces,
							})}
						>
							<Hidden
								hide={!pipelineCreatePageStore.isFetchingSpaces}
							>
								<View paddingBottom={1}>
									<Loader size="small" />
								</View>
							</Hidden>
							<FormControl.Label>Space</FormControl.Label>
						</View>
						<View paddingBottom={1}>
							{!pipelineCreatePageStore.isFetchingSpaces &&
							pipelineCreatePageStore.spaces.length === 1 ? (
								<Text variant="caption-1" color="neutral-faded">
									You currently only have a single space.{" "}
									{createSpaceButton}
								</Text>
							) : (
								<Text variant="caption-1" color="neutral-faded">
									Which Space should this pipeline reside in?{" "}
									{createSpaceButton}
								</Text>
							)}
						</View>
						<Select
							disabled={
								pipelineCreatePageStore.isFetchingSpaces ||
								pipelineCreatePageStore.spaces.length === 1
							}
							options={pipelineCreatePageStore.spaces.map(
								(space) => {
									return {
										label: space.name,
										value: space.id,
									}
								},
							)}
							name="space"
							value={
								pipelineCreatePageStore.setupForm.space.value
							}
							onChange={(event) => {
								pipelineCreatePageStore.setupForm.setSpace(
									event.value,
								)
							}}
						/>
					</FormControl>
					<View align="center">
						<Button
							color="primary"
							onClick={() => {
								pipelineCreatePageStore.nextStep()
							}}
						>
							Next Step
						</Button>
					</View>
				</View>
			</View>
		</form>
	)
})
